import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import {
  H2,
  Button,
  colors,
  Column,
  Headline,
  typeScale,
  PaddedRow,
  BGLightGray,
  PaddedTopRow,
  FixedWidthRow,
} from '../uiLibrary';

import {
  CareersPerk,
  JobPosting,
  InnerPageHero,
} from '../components';
import Layout from '../components/layouts'
import HeadMeta from '../components/HeadMeta'

/**
 * Returns an array with arrays of the given size.
 *
 * @param myArray {Array} Array to split
 * @param chunkSize {Integer} Size of every group
 */
function chunkArray(myArray, chunk_size) {
  var results = [];

  while (myArray.length) {
    results.push(myArray.splice(0, chunk_size));
  }

  return results;
}

const AgnosticLink = styled.a`
  text-decoration: none;
`

const PerkGroup = ({ group }) => (
  group.map((perk) => (
    <Column key={perk.heading} span={6}>
      <CareersPerk iconSrc={perk.image} title={perk.heading}>
        {perk.body}
      </CareersPerk>
    </Column>
  ))
)

const CareersPage = ({ data }) => {
  const page = data.allCareersJson.edges[0].node
  const chunkedPerks = chunkArray(page.perks, 2)

  return (
    <Layout>
      <HeadMeta
        pageTitle={page.pageTitle || 'Careers - Tonit'}
        pageDescription={page.pageDescription}
        socialTitle={page.socialTitle}
        socialDescription={page.socialDescription}
        socialImage={page.socialImage}
      />
      <InnerPageHero url={page.heroBackground} alt="Careers at Tonit">
        <FixedWidthRow>
          <Column span={8}>
            <Headline fontSize={[typeScale.t6, typeScale.t8]}>
              {page.heroHeading}
            </Headline>
            <H2 color={colors.typography.lightSecondary}>
              {page.heroBody}
            </H2>
            <AgnosticLink href={page.heroButtonLink}>
              <Button className='mt4' small href={page.heroButtonLink}>
                {page.heroButtonText}
              </Button>
            </AgnosticLink>
          </Column>
        </FixedWidthRow>
      </InnerPageHero>
      {chunkedPerks.map((group, idx) => {
        if (idx === 0) {
          return (
            <PaddedTopRow key={idx} pt={4}>
              <PerkGroup group={group} />
            </PaddedTopRow>
          )
        } else {
          return (
            <PaddedRow key={idx} pt={4}>
              <PerkGroup group={group} />
            </PaddedRow>
          )
        }
      })}
      <BGLightGray border>
        <PaddedRow>
          <Column id='openings' span={12}>
            <h2>
              {page.openingsHeading}
            </h2>
            {page.openings.map((o, idx) => (
              <JobPosting key={idx} position={o.title} render={() => <ReactMarkdown source={o.details} />} />
            ))}
          </Column>
        </PaddedRow>
      </BGLightGray>
    </Layout>
  )
}

export const query = graphql`
  query {
    allCareersJson {
      edges {
        node {
          heroHeading
          heroBody
          heroBackground
          heroButtonText
          heroButtonLink
          perks {
            heading
            body
            image
          }
          openingsHeading
          openings {
            title
            details
          }
          pageTitle
          pageDescription
          socialTitle
          socialDescription
          socialImage
        }
      }
    }
  }
`

export default CareersPage

